export default {
    rects: [],
    displays: {},
    selectedDisplay: '',
    minimizedDisplays: [],
    isDraggableWidget: true,
    displayIdsAndNames:[],
    showIngSettingsPopUp:false,
    selecteDemoWellName:{}
};
